<template>
  <div>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="院校分数" name="first">
          <Schoolscores v-if="activeName == 'first' "></Schoolscores>
        </el-tab-pane>
        <el-tab-pane label="专业分数" name="second">
          <Majorscores v-if="activeName == 'second' "></Majorscores>
        </el-tab-pane>
        <el-tab-pane label="省控线" name="third">
          <Provincialcontrolline v-if="activeName == 'third' "></Provincialcontrolline>
        </el-tab-pane>
        <el-tab-pane label="分段表" name="fourth">
          <Fragment v-if="activeName == 'fourth' "></Fragment>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import Schoolscores from "./components/schoolscores";
import Majorscores from "./components/majorscores";
import Provincialcontrolline from "./components/Provincialcontrolline";
import Fragment from "./components/fragment";
export default {
  components: {
    Schoolscores,
    Majorscores,
    Provincialcontrolline,
    Fragment,
  },
  data() {
    return {
        activeName: "first",
    };
  },
  mounted() {},
  methods: {
    handleClick(val) {
        console.log(val);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tabs__item.is-active {
    color: #1AB394!important;
}
/deep/.el-tabs__item:hover{
    color: #1AB394!important;
}
/deep/.el-tabs__active-bar{
    background-color:  #1AB394!important;
}
</style>