<template>
  <div>
    <div>
      <el-form :model="form" class="demo-form-inline">
        <el-form-item label="年份">
          <el-select
            v-model="form.year"
            size="small"
            placeholder="不限"
            style="width: 300px"
          >
            <el-option
              v-for="item in yearoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="类别">
          <el-select
            style="width: 300px"
            v-model="form.disciplineType"
            size="small"
            placeholder="不限"
            value-key="id"
          >
            <el-option
              v-for="item in option"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="省份">
          <el-select
            style="width: 300px"
            v-model="form.province"
            size="small"
            placeholder="不限"
            value-key="id"
          >
            <el-option
              v-for="item in optionProvince"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="button">
      <el-button
        type="primary"
        size="small"
        @click="search"
        v-if="validityType == 1"
        >查询剩余{{ usageCount }}次</el-button
      >
      <el-button v-else type="primary" size="small" @click="serach1"
        >查询</el-button
      >
    </div>
    <el-dialog
      title="省控线"
      :visible.sync="dialogTableVisible"
      @closed="getUserQueryCount()"
      width="70%"
    >
      <div>
        <el-table
          :data="tableData"
          style="width: 100%"
          height="500"
          stripe
          :header-cell-style="{
            'text-align': 'center',
            'font-size': '14px',
            color: '#333333',
          }"
          :cell-style="{ 'text-align': 'center', color: '#666666' }"
        >
          <el-table-column label="科别">
            <template slot-scope="scope">
              <div v-if="scope.row.disciplineType == 0">不分文理</div>
              <div v-if="scope.row.disciplineType == 1">理科</div>
              <div v-if="scope.row.disciplineType == 2">文科</div>
              <div v-if="scope.row.disciplineType == 3">物理类</div>
              <div v-if="scope.row.disciplineType == 4">历史类</div>
            </template>
          </el-table-column>
          <el-table-column prop="admitBatch" label="批次"> </el-table-column>
          <el-table-column label="控制线">
            <template slot-scope="scope">
              {{ scope.row.scoreLine ? scope.row.scoreLine : 0 }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          background
          :hide-on-single-page="total <= 10"
          layout="prev, pager, next"
          :total="total"
          @current-change="handleChange"
          :current-page.sync="form.current"
          :page-size="form.size"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProvinceScore,
  getProvinceList,
  getUserQueryCount,
  updateUseState,
} from "@/api/dataquery";
export default {
  data() {
    return {
      dialogTableVisible: false,
      form: { current: 1, size: 10 },
      tableData: [],
      yearoptions: [],
      usageCount: 0,
      total: 0,
      optionProvince: [],
      validityType: 2,
      option: [
        { label: "不分文理", value: 0 },
        { label: "理科", value: 1 },
        { label: "文科", value: 2 },
        { label: "物理类", value: 3 },
        { label: "历史类", value: 4 },
      ],
      validityTime: "",
    };
  },
  mounted() {
    let student1 = sessionStorage.getItem("student") || {};
    let student = JSON.parse(student1);
    this.form.disciplineType = student.disciplineType || 0;
    this.form.province = { name: student.examProvince, id: student.province };
    this.form = { ...this.form };
    this.getUserQueryCount();
    this.getProvinceList();
    this.getyearsoptions();
  },
  methods: {
    // 查询
    search() {
      if (this.usageCount > 0) {
        updateUseState({ type: 3 }).then((res) => {
          if (res.code == 1) {
            this.dialogTableVisible = true;
            this.getProvinceScore();
          }
        });
      } else {
        this.$message.error("查询次数不足，请前往OK志愿小程序购买");
      }
    },
    serach1() {
      let validDate = new Date(this.validityTime.replace(/-/, "/"));
      if (new Date().getTime() <= validDate.getTime()) {
        this.dialogTableVisible = true;
        this.getProvinceScore();
      } else {
        this.$message.error("有效期已过期，请前往OK志愿小程序购买");
      }
    },
    // 查询次数
    getUserQueryCount() {
      getUserQueryCount({ type: 3 }).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          this.validityType = data.validityType;
          this.validityTime = data.validityTime;
          this.usageCount = data.usageCount || 0;
        }
      });
    },
    handleChange(page) {
      this.form.current = page;
      this.getProvinceScore();
    },
    //列表
    getProvinceScore() {
      getProvinceScore(this.form).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          this.total = data.total || 0;
          this.tableData = data.records || [];
        }
      });
    },
    // 省份
    getProvinceList() {
      getProvinceList({}).then((res) => {
        if (res.code == 1) {
          this.optionProvince = res.data || [];
        }
      });
    },
    //获取年份
    getyearsoptions() {
      let date = new Date();
      let year = date.getFullYear();
      this.form.year = year;
      this.form = { ...this.form };
      for (let i = 0; i < 10; i++) {
        this.yearoptions.push({ label: year - i, value: year - i });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.button {
  display: flex;
  justify-content: center;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1ab394;
}
/deep/.el-pager li:hover {
  color: #1ab394 !important;
}
/deep/.el-button--primary {
  color: #fff;
  background-color: #1ab394;
  border-color: #1ab394;
}
// /deep/.el-select-dropdown__item.selected {
//   color: #1ab394 !important;
//   font-weight: 700;
// }
/deep/.el-table--striped
  .el-table__body
  tr.el-table__row--striped
  td.el-table__cell {
  background: #f8f8f8;
}
.pagination {
  margin-top: 40px;
}
</style>
