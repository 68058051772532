import { render, staticRenderFns } from "./Provincialcontrolline.vue?vue&type=template&id=586de360&scoped=true"
import script from "./Provincialcontrolline.vue?vue&type=script&lang=js"
export * from "./Provincialcontrolline.vue?vue&type=script&lang=js"
import style0 from "./Provincialcontrolline.vue?vue&type=style&index=0&id=586de360&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "586de360",
  null
  
)

export default component.exports