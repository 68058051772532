import axios from '@/util/request'
import service from '@/util/request'

//高考动态

// GET /mobile/expert-info/getExpertDetail
// 查看专家详情
export const getExpertDetail = (params) => {
        return service({
            url: '/mobile/expert-info/getExpertDetail',
            method: 'get',
            params
        })

    }
    // GET /com/sys/sys-area/getProvinceList
    // 查询所有省份
export const getProvinceList = (params) => {
        return axios({
            url: '/com/sys/sys-area/getProvinceList',
            method: 'get',
            params
        })

    }
    // POST /mobile/college-info/getCollegeList
    // 找学校

export const getCollegeList = (data) => {
    return axios({
        url: '/mobile/college-info/getCollegeList',
        method: 'post',
        data
    })

}

// /mobile/specialty-info/getSpecialtyTree
// 获取所有专业树形结构（传name搜索,且只能搜专业(第三级)）
export const getSpecialtyTree = (params) => {
    return axios({
        url: '/mobile/specialty-info/getSpecialtyTree',
        method: 'get',
        params
    })

}

// /mobile/specialty-info/getSpecialtyDetail
// 查看专业详情
export const getSpecialtyDetail = (params) => {
    return axios({
        url: '/mobile/specialty-info/getSpecialtyDetail',
        method: 'get',
        params
    })

}

// /com/had-login/mobile/admission-info/getCollegeAdmission
// 查询院校分数
export const getCollegeAdmission = (data) => {
    return axios({
        url: '/com/had-login/mobile/admission-info/getCollegeAdmission',
        method: 'post',
        data
    })

}

// /com/had-login/mobile/user-info/getUserQueryCount
// 查询用户剩余服务次数或期限（type:1兴趣测评 2模拟志愿 3分数查询）
export const getUserQueryCount = (params) => {
    return axios({
        url: '/com/had-login/mobile/user-info/getUserQueryCount',
        method: 'get',
        params
    })
}

// /com/had-login/mobile/user-info/updateUseState
// 标记当前服务正在使用（type:1兴趣测评 2模拟志愿 3分数查询）
export const updateUseState = (params) => {
    return axios({
        url: '/com/had-login/mobile/user-info/updateUseState',
        method: 'get',
        params
    })
}

// /com/had-login/mobile/admission-info/getSpecialtyAdmission
// 查询专业分数
export const getSpecialtyAdmission = (data) => {
    return axios({
        url: '/com/had-login/mobile/admission-info/getSpecialtyAdmission',
        method: 'post',
        data
    })

}

// /com/had-login/mobile/admission-info/getProvinceScore
// 查询省控线（批次线）
export const getProvinceScore = (data) => {
    return axios({
        url: '/com/had-login/mobile/admission-info/getProvinceScore',
        method: 'post',
        data
    })

}

// /com/had-login/mobile/admission-info/getProvinceSegment
// 查询分段表
export const getProvinceSegment = (data) => {
    return axios({
        url: '/com/had-login/mobile/admission-info/getProvinceSegment',
        method: 'post',
        data
    })

}

// /mobile/college-info/getUniversityInfo
// 查看单个详情
export const getUniversityInfo = (params) => {
    return axios({
        url: '/mobile/college-info/getUniversityInfo',
        method: 'get',
        params
    })
}

// /mobile/college-info/getUniversityRankingList
// 查看学校排名
export const getUniversityRankingList = (data) => {
    return axios({
        url: '/mobile/college-info/getUniversityRankingList',
        method: 'post',
        data
    })
}

// /mobile/college-info/getUniversityAdmitData
// 查看学校录取数据
export const getUniversityAdmitData = (data) => {
    return axios({
        url: '/mobile/college-info/getUniversityAdmitData',
        method: 'post',
        data
    })
}

// /mobile/college-info/getUniversitySpecialtyData
// 查看专业录取数据
export const getUniversitySpecialtyData = (data) => {
    return axios({
        url: '/mobile/college-info/getUniversitySpecialtyData',
        method: 'post',
        data
    })
}

// /com/had-login/mobile/user-info/getOneProvince
// 根据年份和省份(地区)id查询省份批次
export const getOneProvince = (params) => {
    return axios({
        url: '/com/had-login/mobile/user-info/getOneProvince',
        method: 'get',
        params
    })
}