<template>
  <div class="bigbox">
    <div>
      <el-form :model="form" class="demo-form-inline">
        <el-form-item label="年份">
          <el-select v-model="form.year" size="small" placeholder="不限" style="width: 300px" @change="yearChange">
            <el-option v-for="item in yearoptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="科别">
          <el-select style="width: 300px" v-model="form.disciplineType" size="small" placeholder="不限" value-key="id">
            <el-option v-for="item in option" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="批次">
          <el-select style="width: 300px" v-model="form.admitBatch" size="small" placeholder="不限" value-key="id">
            <el-option v-for="item in batchOption" :key="item.batch" :label="item.batch" :value="item.batch">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="院校">
          <el-input size="small" style="width: 300px" v-model="form.collegeName" placeholder="请输入院校名称"></el-input>
        </el-form-item>
        <el-form-item label="分段">
          <el-input v-model="form.scoreMin" placeholder="最低分" size="small" style="width: 145px" clearable></el-input>
          -
          <el-input v-model="form.scoreMax" placeholder="最高分" size="small" style="width: 145px" clearable></el-input>
        </el-form-item>
        <el-form-item label="位次">
          <el-input v-model="form.accumulateMin" placeholder="最低位" size="small" style="width: 145px" clearable>
          </el-input>
          -
          <el-input v-model="form.accumulateMax" placeholder="最高位" size="small" style="width: 145px" clearable>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="button">
      <el-button v-if="validityType == 1" type="primary" size="small" @click="search">查询(剩余{{ usageCount }}次)
      </el-button>
      <el-button v-else type="primary" size="small" @click="serach1">查询</el-button>
    </div>
    <el-dialog title="院校分数" :visible.sync="dialogTableVisible" @closed="getUserQueryCount()" width="80%">
      <div>
        <el-table :data="tableData" style="width: 100%" stripe height="500" :header-cell-style="{
          'text-align': 'center',
          'font-size': '14px',
          color: '#333333',
        }" :cell-style="{ 'text-align': 'center', color: '#666666' }">
          <el-table-column prop="collegeName" label="院校名称" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="collegeProvince" label="所在省份">
          </el-table-column>
          <el-table-column label="院校特性">
            <template slot-scope="scope">
              {{ filterdCollegeCharacter(scope.row.collegeCharacter + "") }}
            </template>
          </el-table-column>
          <el-table-column prop="province" label="省|直辖市"> </el-table-column>
          <el-table-column label="科别">
            <template slot-scope="scope">
              {{ filterdDisciplineType(scope.row.disciplineType) }}
            </template>
          </el-table-column>
          <el-table-column prop="year" label="年份"> </el-table-column>
          <el-table-column prop="admitBatch" label="批次"> </el-table-column>
          <el-table-column label="省控线">
            <template slot-scope="scope">
              {{ scope.row.scoreLine ? scope.row.scoreLine : 0 }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="录取人数" width="120px">
            <template slot-scope="scope">
              {{ scope.row.actualNum ? scope.row.actualNum : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="最高分" width="100px">
            <template slot-scope="scope">
              {{ scope.row.scoreHighest ? scope.row.scoreHighest : 0 }}
            </template>
          </el-table-column> -->
          <el-table-column label="最低分" width="100px">
            <template slot-scope="scope">
              {{ scope.row.scoreLowest ? scope.row.scoreLowest : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="最低位次" width="100px">
            <template slot-scope="scope">
              {{ scope.row.accumulateLowest ? scope.row.accumulateLowest : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="分差" width="100px">
            <template slot-scope="scope">
              {{
                  (scope.row.scoreLowest ? scope.row.scoreLowest : 0) -
                  (scope.row.scoreLine ? scope.row.scoreLine : 0)
              }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination background :hide-on-single-page="total <= 10" layout="prev, pager, next" :total="total"
          @current-change="handleChange" :current-page.sync="form.current" :page-size="form.size"></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCollegeAdmission,
  getProvinceList,
  getUserQueryCount,
  updateUseState,
  getOneProvince,
} from "@/api/dataquery";
export default {
  data() {
    return {
      dialogTableVisible: false,
      form: { current: 1, size: 20 },
      tableData: [],
      yearoptions: [],
      usageCount: 0,
      validityType: 2,
      total: 0,
      batchOption: [],
      validityTime: "",
      optionProvince: [],
      option: [],
    };
  },
  mounted() {
    let student1 = sessionStorage.getItem("student") || {};
    let student = JSON.parse(student1);
    this.form.disciplineType = student.disciplineType || 0;
    this.form.province = { name: student.examProvince, id: student.province };
    this.form = { ...this.form };
    this.getOneProvince(new Date().getFullYear());
    this.getUserQueryCount();
    this.getProvinceList();
    this.getyearsoptions();
  },
  methods: {
    getOneProvince(year) {
      getOneProvince({
        year: year,
        areaProvinceId: this.form.province.id,
      }).then((res) => {
        if (res.code == 1 && res.data) {
          this.option = []
          if (res.data.pattern == 1) {
            this.option.push({
              value: 0,
              label: "不分文理"
            })
          } else if (res.data.pattern == 2) {
            this.option.push({
              value: 3,
              label: "物理类"
            })
            this.option.push({
              value: 4,
              label: "历史类"
            })
          } else if (res.data.pattern == 3) {
            this.option.push({
              value: 1,
              label: "理科"
            })
            this.option.push({
              value: 2,
              label: "文科"
            })
          }
          this.form.disciplineType = this.option[0].value
          this.batchOption = res.data.batchs || [];
          this.form.admitBatch = this.batchOption[0].batch;
          this.form = { ...this.form };
        }
      });
    },
    yearChange(year) {
      this.getOneProvince(year)
    },
    filterdCollegeCharacter(character) {
      let collegeCharacter = "";
      if (character.includes("2")) {
        collegeCharacter += "985、";
      }
      if (character.includes("1")) {
        collegeCharacter += "211、";
      }
      if (character.includes("3")) {
        collegeCharacter += "双一流、";
      }
      return collegeCharacter.substring(0, collegeCharacter.length - 1);
    },
    // 查询
    search() {
      if (this.usageCount > 0) {
        updateUseState({ type: 3 }).then((res) => {
          if (res.code == 1) {
            this.dialogTableVisible = true;
            this.getCollegeAdmission();
          }
        });
      } else {
        this.$message.error("查询次数不足，请前往OK志愿小程序购买");
      }
    },
    serach1() {
      let validDate = new Date(this.validityTime.replace(/-/, "/"));
      if (new Date().getTime() <= validDate.getTime()) {
        this.dialogTableVisible = true;
        this.getCollegeAdmission();
      } else {
        this.$message.error("有效期已过期，请前往OK志愿小程序购买");
      }
    },
    // 查询次数
    getUserQueryCount() {
      getUserQueryCount({ type: 3 }).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          this.validityType = data.validityType;
          this.validityTime = data.validityTime;
          this.usageCount = data.usageCount || 0;
        }
      });
    },
    handleChange(page) {
      this.form.current = page;
      this.getCollegeAdmission();
    },
    //列表
    getCollegeAdmission() {
      getCollegeAdmission(this.form).then((res) => {
        if (res.code == 1) {
          let data = res.data || {};
          this.total = data.total || 0;
          this.tableData = data.records || [];
        }
      });
    },
    // 省份
    getProvinceList() {
      getProvinceList({}).then((res) => {
        if (res.code == 1) {
          this.optionProvince = res.data || [];
        }
      });
    },
    //获取年份
    getyearsoptions() {
      let date = new Date();
      let year = date.getFullYear();
      this.form.year = year;
      this.form = { ...this.form };
      for (let i = 0; i < 10; i++) {
        this.yearoptions.push({ label: year - i, value: year - i });
      }
    },
    filterdDisciplineType(type) {
      switch (type) {
        case 0:
          return "不分文理";
        case 1:
          return "理科";
        case 2:
          return "文科";
        case 3:
          return "物理类";
        case 4:
          return "历史类";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bigbox {
  width: 910px;

  .button {
    display: flex;
    justify-content: center;
  }
}

/deep/.el-button--primary {
  color: #fff;
  background-color: #1ab394;
  border-color: #1ab394;
}

// /deep/.el-select-dropdown__item.selected {
//   color: #1ab394 !important;
//   font-weight: 700;
// }
/deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #f8f8f8;
}

.pagination {
  margin-top: 40px;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1ab394;
}

/deep/.el-pager li:hover {
  color: #1ab394 !important;
}
</style>
